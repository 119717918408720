import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';
import i18n from  '../i18n';
import Logo from '../img/logo.svg';

export default function Footer(props) {
  const lng = i18n.language.split('-')[0];
  const baseUrl = '/' + lng + '/';
  const {t} = useTranslation();

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col sm={8}>
            <Link to="/" className="site-logo" title="pimaxplus.com">
              <div className="logo-img">
                <img src={Logo} alt="pimaxplus.com" />
              </div>
              <div className="name-and-tagline">
                <div className="name">
                  pimaxplus<span className="red">.com</span>
                </div>
                <div className="tagline">
                  IT consultancy
                </div>
              </div>
            </Link>
            <p>{t('Footer text')}</p>
            <p>
              {t('Copyright text', {year: new Date().getFullYear()})}<br />
              <small><Link to={baseUrl + 'cookies'}>Cookie policy</Link></small>
            </p>
          </Col>
          <Col sm={4}>
            <h4>{t('Keep in touch')}</h4>
            <p>
              {link('info@pimaxplus.com', 'mailto:info@pimaxplus.com', faEnvelope)}
            </p>
            <p>
              {link('pimaxplus', 'https://linkedin.com/company/pimaxplus', faLinkedin)}<br />
              {link('@pimaxplus', 'https://twitter.com/pimaxplus', faTwitter)}<br />
              {link('@pimaxplus', 'https://www.facebook.com/pimaxplus', faFacebook)}
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

function link(title, url, icon) {
  return (
    <a href={url} className="font-weight-bold" title={title} target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={icon} className="mr-2" />
      {title}
    </a>
  );
}